import React from "react";
import Layout from "../components/layout";
import { graphql, Link } from "gatsby";
import { altMapImages } from "../utils";
import ImageGrid from "../components/imageGrid";
import SEO from "../components/seo";

export const getImagesQuery = graphql`
  query GetImages($imagePath: String!) {
    images: allFile(
      sort: {order: DESC, fields: name},
      filter: {
        relativeDirectory: { regex: $imagePath}
      }
    ) {
      edges {
        node {
          relativeDirectory
          name
          childImageSharp {
            ...imageSetupFragment
          }
        }
      }
    }
  }
`

export default function Project({pageContext, data}) {

  const coasterImages = altMapImages(
    data.images.edges,
    pageContext.altDescriptions
  );

  return (
    <Layout>
      <SEO
        title={pageContext.title}
        description={pageContext.intro}
        image={coasterImages[0].original}
        imageAlt={coasterImages[0].alt}
      />
      <main className="w-full flex-grow max-w-screen-xl mx-auto py-4 px-4-safe-area sm:px-6-safe-area" role="main">
        <h1 className="text-3xl pt-1 pb-2 md:text-4xl md:pb-4 md:pt-5 lg:pt-6">{pageContext.title}</h1>
        <p className="text-md mb-4">{pageContext.intro} If you'd like to know more, or to request your own commission <Link className="underline" to="/contact/">contact me</Link>.</p>
        <ImageGrid images={coasterImages} />
      </main>
    </Layout>
  )
}